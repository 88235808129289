import React, { useState } from "react"
// import Abogados from "../components/seccionAbogados"
import Layout from "../components/en/ENlayout"
import SEO from "../components/seo"

export default function nosotros() {
  return (
    <Layout>
      <SEO title="nosotros" />
    </Layout>
  )
}
